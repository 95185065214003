<template>
  <div class="accept-tos">
    <div class="imagecontainer">
      <img alt="SpringboardVR" src="../assets/sbvr-dark-logo.svg">
    </div>
    <Panel>
      <template v-if="loading">
        <UIGradientLoader class="mb4" />
        <UIGradientLoader class="mb4" />
        <UIGradientLoader />
      </template>
      <div v-if="!loading" class="animated fadeIn">
        <div class="toscontent sbvr-standard" v-html="currentTosModal"></div>
        <div class="buttons">
          <UIButton ghost @click="decline">{{ $t('Decline') }}</UIButton>
          <UIButton @click="accept">{{ $t('Accept') }}</UIButton>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import Panel from '../components/Panel';
import UIButton from '../components/ui/UIButton';
import UIGradientLoader from '../components/ui/UIGradientLoader';

@Component({
  components: {
    Panel,
    UIButton,
    UIGradientLoader,
  },
})
export default class CurrentTermsOfServiceRoute extends Vue {
  currentTosModal = '';
  loading = true;

  async created () {
    const data = await this.$store.dispatch('getCurrentTermsOfService');
    this.currentTosModal = data.me.organization.tenant.currentTosModal;
    this.loading = false;
  }

  decline () {
    this.$store.commit('setFlash', {
      message: this.$t("You've declined our Terms of Service. Please contact support@springboardvr.com"),
      type: 'info',
    });
  }

  async accept () {
    await this.$store.dispatch('acceptCurrentTOS');
  }
}
</script>

<style lang="postcss" scoped>
@import "../styles";

.accept-tos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: var(--spacingSm);

  @media (--tablet) {
    padding: var(--spacingMd);
  }

  & img {
    width: 100%;
    max-width: 30rem;
    margin-bottom: var(--spacingSm);
  }

  & .imagecontainer {
    width: 100%;
    max-width: 70rem;
  }

  & .panel {
    width: 100%;
    max-width: 70rem;
  }

  & .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--spacingMd);

    & button {
      padding-left: var(--spacingLg);
      padding-right: var(--spacingLg);
    }

    & button:first-child {
      margin-right: var(--spacingSm);
    }
  }
}
</style>
